jQuery(document).ready(function($) {
	var domain = window.location.host;
	if (typeof window.langSwitchURL !== 'undefined') {
		domain = window.langSwitchURL;
	}
	else {
		// Remove www
		if (domain.indexOf('www') >= 0) {
			hasWWW = true
			domain = domain.substr(4, domain.length - 1)
		}
		// Remove the language
		domain = domain.substr(domain.indexOf('.') + 1, domain.length - 1)
	}

	// Prepare navbars content
	var navbarsContent = "<div class='div-block-6'>"
	navbarsContent += "<a href='//" + domain + "' class='language language-mobile'>" +
							"<div class='language-text'>Ελληνικα</div>" +
						"</a>" +
						"<div class='div-block-7'>"

	if ( typeof window.socialMedia.facebook !== "undefined" ) {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.facebook + "' class='link-block-2-copy w-inline-block'>" +
		"<img src='/20200410105500/local/public/shared/assets/images/websites/fb.svg' alt='Charalambides Christis facebook page'>" +
		"</a>"
	}
	if ( typeof window.socialMedia.instagram !== "undefined" ) {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.instagram + "' class='link-block-2 w-inline-block'>" +
		"<img src='/20200410105500/local/public/shared/assets/images/websites/insta.svg' alt='Charalambides Christis instagram page'>" +
		"</a>"
	}
	if ( typeof window.socialMedia.youtube !== "undefined" ) {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.youtube + "' class='link-block-2 w-inline-block'>" +
		"<img src='/20200410105500/local/public/shared/assets/images/websites/icon_youtube.svg' alt='Charalambides Christis youtube channel'>" +
		"</a>"
	}
	if ( typeof window.socialMedia.linkedin !== "undefined" ) {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.linkedin + "' class='link-block-2 w-inline-block'>" +
		"<img src='/20200410105500/local/public/shared/assets/images/websites/linkedin.svg' alt='Charalambides Christis linkedin page'>" +
		"</a>"
	}

	if (typeof window.socialMedia.tiktok !== "undefined") {
		navbarsContent += "<a target='_blank' rel='noopener' href='" + window.socialMedia.tiktok + "' class='link-block-2 w-inline-block'>" +
			"<img src='/20200410105500/local/public/shared/assets/images/websites/icon-tiktok-outline-blue.svg' alt='TikTok'>" +
			"</a>"
	}

	navbarsContent += "</div>" +
	"</div>"

	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right",
			"pagedim-black"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		},
		navbars: [
			{
				position: "top",
				content: [
					navbarsContent
				]
			}
		]
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});
});
